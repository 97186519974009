// @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #000000;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import "~react-pro-sidebar/dist/scss/styles.scss";
body {
  margin: 0;
  height: 100vh;
  color: #000000;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;

  > div {
    height: 100%;
  }
}

.app {
  height: 100vh;
  position: relative;
  .body {
    height: 100%;
    padding-left: 55px;
    @media (max-width: 1199px) {
      padding-left: 0;
      &.open {
        right: -270px;
        position: relative;
      }
    }
  }
  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  .pro-sidebar .pro-menu a .activeSidebar {
    color: #e9ac00;
  }

  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    border-top: none;
    margin-bottom: 45px;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none;
  }
  .pro-sidebar .pro-menu .pro-menu-item + .pro-menu-item {
    margin-top: 10px;
  }
  .pro-sidebar .pro-arrow-wrapper {
    display: none;
  }
  .pro-sidebar .react-slidedown {
    overflow: hidden;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: transparent;
  }

  .square-legend .apexcharts-legend {
    .apexcharts-legend-series {
      margin: 0 3px !important;
      border: 1px solid #d9e1e7;
      border-radius: 3px;
      padding: 8px 5px;
      .apexcharts-legend-marker {
        width: 15px !important;
        height: 15px !important;
        border-radius: 2px !important;
        margin-right: 8px;
        margin-top: -1px;
        vertical-align: -3px;
      }
      .apexcharts-legend-text {
        color: #8b8ea3;
        font-weight: bold;
        font-size: 11px;
      }
    }
  }

  .c-tab {
    margin: 15px 0 -15px 0;
    padding: 0;
    li {
      width: 100%;
      list-style: none;
      text-align: center;
      padding: 0 3px;
      a {
        display: block;
        color: #777777;
        font-weight: bold;
        font-size: 14px;
        border-radius: 4px;
        background-color: #ebebeb;
        padding: 6px 5px;
        &.active,
        &:hover {
          background-color: #3e3e3e;
          color: #f5b600;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }
  }
}



@media (max-width: 1200px) {
  .small-l {
    display: none;
  }
  .app .c-tab {
    margin: 10px 0 -10px 0;
  }
}
@media (min-width: 1200px) {
  .pro-sidebar {
    min-width: auto;
    &:not(:hover) {
      width: 55px;
      .logo {
        opacity: 0;
      }
      .big-l {
        display: none;
      }
      .logo {
        width: 52px;
        overflow: hidden;
      }
      .small-l {
        display: block;
      }
    }
    .small-l {
      display: none;
      max-width: 55px;
      padding: 0 10px;
    }
    .pro-menu {
      margin-top: 30px;
      .pro-menu-item > .pro-inner-item {
        padding: 11px 15px;
      }
    }
   

    // display: none;
  }
  .pro-sidebar:hover .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 11px 35px;
  }
}
