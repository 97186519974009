.header-filter {
    .calender-icon {
        margin: 0 3px;

        .calendar_wrapper {
            left: 0;
        }

        .first-calender {
            left: auto;
            right: 0;
        }
    }

    .ant-select,
    .margin {
        margin: 0 3px;
    }

    .c-tab {
        width: 325px;
    }

    .ant-select:not(.ant-select-customize-input).input-antd .ant-select-selector {
        height: 33px !important;

        input {
            font-size: 15px;
            font-weight: 700;
            color: #5a5e7c;
            height: 100%;
        }
        .ant-select-selection-placeholder{
            line-height: 33px !important;
            font-size: 15px !important;
            font-weight: 700 !important;
            color: #5a5e7c !important;
        }
    }

    .header-cal-border {
        border-color: #f5b600;
    }
}