.body-content {
    .container_is-open{
      max-width: 95%;
      margin-left:100px;
    }
    // .content {
    //   padding: 20px;
    //   margin-left: 0;
    //   height: 100vh;
    // }

    // @media only screen and (max-width: 768px) {
    //   .body-content{
    //     .container{
    //       max-width:100%;
    //       margin-left:0;
    //     }
    //   }
    // }
      @media only screen and (max-width: 500px) {
        body {
          overflow: hidden;
        }
      
        .container.is-open {
          margin-left: 100%;
        }
      
        .sidebar.is-open {
          min-width: 100%;
          max-width: 100%;
          margin-left: 0;
          transition: all 0.5s, height 0s;
        }
      
        .sidebar.is-open > .sidebar-header span {
          display: unset;
        }
      
        li a.dropdown-toggle::after {
          display: inline-block;
          position: relative;
          left: 68%;
        }
      }


}