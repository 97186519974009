.pro-sidebar {
    position: fixed;
    top: 0;
    left: 0;
}

.header-wrapper {
    background-color: white;
    height: 68.8px;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: padding, left, right, 0.3s;

    .header-left {
        display: flex;
        align-items: center;

        ul {
            display: flex;
            list-style-type: none;
            align-items: center;
            padding: 0;
        }
    }

    .header {
        background-color: white;
        width: 100%;
    }

    .block {
        margin-bottom: 0;
    }

    .header-right {
        ul {
            list-style-type: none;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            margin-top: 0;

            li {
                padding: 0 0.7rem;

                .nav-link {
                    padding: 0.5rem 0;
                }
            }
        }
    }
}

.pro-sidebar {
    box-shadow: 0 1px 4px 1px #bebebe;

    > .pro-sidebar-inner {
        background-color: #1c1f2b;
        color: white;
    }

    .pro-menu {
        .pro-menu-item {
            > .pro-inner-item {
                &:hover {
                    .pro-item-content {
                        color: #f5b600;
                    }
                }

                > .pro-item-content {
                    color: #767d97;
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: 600;
                }
            }
        }

        .pro-menu-item.active {
            > .pro-inner-item {
                > .pro-item-content {
                    color: #f5b600;
                }
            }
        }
        .pro-sub-menu.active {
            > .pro-inner-item {
                > .pro-item-content {
                    color: #f5b600;
                }
            }
        }
    }
}

.submenu-prefix {
    padding-left: 28px;
}

.right {
    margin-left: -540px;
}

.rightPlus {
    margin-left: -715px;
}

.profileMenu {
    margin-right: 10px;
    margin-top: 2px;
}

.userProfile {
    margin-right: 10px;
    margin-top: -2px;
}
